import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import Editor from "@monaco-editor/react";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import SkeletonLoading from "../../components/common/_skeletonLoading";
// import { explorer } from "./folderData";
import Folder from "./Folder";
import axios from "axios";

const CondenseEditor = () => {
  const [explorerData, setExplorerData] = useState({});
  const [tempExplorerData, setTempExplorerData] = useState({});
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [editorValueToShow, setEditorValueToShow] = useState("");
  const [selectedFileId, setSelectedFileId] = useState("");
  const [tempoCode, setTempoCode] = useState("");
  const [isTabClosed, setIsTabClosed] = useState(false);
  const [isRightClicked, setIsRightClicked] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [isTabHover, setIsTabHover] = useState(false);

  const [allRepos, setAllRepos] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [allCommits, setAllCommits] = useState([]);
  const [selectedRepo, setSelectedRepo] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [isFolderTreeFetching, setIsFolderTreeFetching] = useState(false);
  const [folderTree, setFolderTree] = useState({});

  const fetchRepoData = async () => {
    try {
      const stringifiedData = {
        gitToken: "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",
      };

      let response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "github/getAllRepo",
        stringifiedData
      );

      // console.log("Repo Response: ", response.data.allRepos);
      setAllRepos(response.data.allRepos);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const fetchBranchData = async () => {
    try {
      const stringifiedData = {
        gitUsername: "ratna9429",
        gitRepo: selectedRepo,
        gitToken: "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",
      };

      let response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "github/getAllBranchesOfRepo",
        stringifiedData
      );

      // console.log("Branch Response: ", response.data.allBranches);
      setAllBranches(response.data.allBranches);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const fetchFolderTree = async (branchName) => {
    setExplorerData({});
    setTempExplorerData({});
    setIsFolderTreeFetching(true);

    try {
      const stringifiedData = {
        gitUsername: "ratna9429",
        gitRepo: selectedRepo,
        gitToken: "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",
        gitBranch: branchName,
      };

      let response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "github/gitRepoFolderTree",
        stringifiedData
      );

      // console.log("Folder Tree Response: ", response.data);

      setExplorerData(response.data.folderTree);
      setTempExplorerData(response.data.folderTree);
    } catch (error) {
      console.log("Error: ", error);
    }

    setIsFolderTreeFetching(false);
  };

  const fetchCommits = async (branchName) => {
    try {
      const stringifiedData = {
        gitUsername: "ratna9429",
        gitRepo: selectedRepo,
        gitToken: "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",
        gitBranch: branchName,
      };

      let response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "github/getAllCommitsOfBranch",
        stringifiedData
      );

      // console.log("All commits Response: ", response.data.allCommits);
      setAllCommits(response.data.allCommits);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    if (selectedRepo != "Select Repo" || selectedRepo == "") {
      fetchRepoData();
    }
  }, []);

  useEffect(() => {
    if (selectedRepo != "") {
      fetchBranchData();
    }
  }, [selectedRepo]);

  const handleRepoSelect = (event) => {
    setSelectedRepo(event.target.value);
    setAllBranches([]);
    setSelectedBranch("");
    setExplorerData({});
    setTempExplorerData({});
  };

  const handleBranchSelect = (event) => {
    setSelectedBranch(event.target.value);

    if (event.target.value != "Select Branch") {
      fetchFolderTree(event.target.value);
      fetchCommits(event.target.value);
    }
  };

  const handleMouseOver = () => {
    setIsTabHover(true);
  };

  const handleMouseOut = () => {
    setIsTabHover(false);
  };

  const handleTabClick = async (tab) => {
    setSelectedFileId(tab.id);

    await clearEditorValue();
    for (let object of tabs) {
      if (object.id == tab.id) {
        object.isActivated = true;
      } else {
        delete object.isActivated;
      }
    }

    setEditorValueToShow(tab.code);
  };

  const handleTabClose = async (id) => {
    let updatedTabs = tabs.filter((item) => item.id !== id);

    if (updatedTabs.length > 0) {
      setTempoCode(updatedTabs[updatedTabs.length - 1].code);

      setEditorValueToShow("");
      setTabs(updatedTabs);

      for (let object of tabs) {
        if (object.id == updatedTabs[updatedTabs.length - 1].id) {
          object.isActivated = true;
        } else {
          delete object.isActivated;
        }
      }

      setIsTabClosed(!isTabClosed);
    } else {
      setTabs([]);
      setEditorValueToShow("");
    }
  };

  useEffect(() => {
    setEditorValueToShow(tempoCode);
  }, [isTabClosed]);

  const clearEditorValue = async () => {
    await setEditorValueToShow("");
  };

  useEffect(() => {
    if (isRightClicked != false) {
      setEditorValueToShow("");
    }

    setIsRightClicked(null);
  }, [isButtonClicked]);

  const handleRightClick = (showMenuState) => {
    setIsRightClicked(showMenuState);
  };

  const updateTabsArray = async (
    selectedFileId,
    selectedFileName,
    selectedFileCode
  ) => {
    if (tabs.length > 0) {
      await clearEditorValue();
    }

    setSelectedFileId(selectedFileId);
    const found = tabs.find((element) => element.id == selectedFileId);

    if (found == undefined) {
      setTabs([
        ...tabs,
        {
          id: selectedFileId,
          name: selectedFileName,
          code: selectedFileCode,
          isActivated: true,
        },
      ]);

      setEditorValueToShow(selectedFileCode);

      if (tabs.length > 0) {
        for (let object of tabs) {
          if (object.id == selectedFileId) {
            object.isActivated = true;
          } else {
            delete object.isActivated;
          }
        }
      }
    } else {
      setEditorValueToShow(selectedFileCode);

      for (let object of tabs) {
        if (object.id == selectedFileId) {
          object.isActivated = true;
        } else {
          delete object.isActivated;
        }
      }
    }
  };

  const handleInsertNode = (folderId, item, isFolder) => {
    const finalTree = insertNode(explorerData, folderId, item, isFolder);
    setExplorerData(finalTree);
  };

  const insertNode = function (tree, folderId, item, isFolder) {
    if (tree.id === folderId && tree.isFolder) {
      tree.items.unshift({
        id: new Date().getTime(),
        name: item,
        isFolder: isFolder,
        code: isFolder == true ? "" : "//Start writing code from here...",
        isSelected: false,
        items: [],
      });

      return tree;
    }

    let latestNode = [];
    latestNode = tree.items.map((ob) => {
      return insertNode(ob, folderId, item, isFolder);
    });

    return { ...tree, items: latestNode };
  };

  const handleDeleteNode = (finalTree, selectedId) => {
    setExplorerData(finalTree);

    let updatedTabs = tabs.filter((item) => item.id !== selectedId);

    setTabs(updatedTabs);
  };

  const handleRenameNode = (finalTree, selectedId, inputValue) => {
    setExplorerData(finalTree);

    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].id === selectedId) {
        tabs[i].name = inputValue;
        break;
      }
    }
  };

  const setButtonClick = () => {
    setIsButtonClicked(!isButtonClicked);
  };

  const updateExplorer = (id, newCode, explorerData) => {
    if (explorerData.id === id) {
      explorerData.code = newCode;
    } else if (explorerData.items) {
      explorerData.items.forEach((item) => updateExplorer(id, newCode, item));
    }
  };

  const handleEditorChange = async (value, event) => {
    // here is the current value
    // console.log("value:", value);
    // console.log("event:", event);

    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].id === selectedFileId) {
        tabs[i].code = value;
        setTabs(tabs);
        break;
      }
    }

    await updateExplorer(selectedFileId, value, explorerData);
    await setExplorerData(explorerData);
  };

  const handleEditorDidMount = (editor, monaco) => {
    // console.log("onMount: the editor instance:", editor);
    // console.log("onMount: the monaco instance:", monaco);
  };

  const handleEditorWillMount = (monaco) => {
    // console.log("beforeMount: the monaco instance:", monaco);
  };

  const handleEditorValidation = (markers) => {
    // model markers
    // markers.forEach(marker => console.log('onValidate:', marker.message));
  };

  return (
    <>
      <Row xs={12} style={{ textAlign: "start", marginBottom: "10px" }}>
        <Col xs={2}>
          <select
            className="form-select codeEditorFormSelect"
            aria-label="Default select example"
            onChange={handleRepoSelect}
          >
            <option selected>Select Repo</option>
            {allRepos.map((eachItem) => {
              return (
                <option value={eachItem.repoName}>{eachItem.repoName}</option>
              );
            })}
          </select>
        </Col>

        <Col xs={2}>
          <select
            className={
              selectedRepo == "" || selectedRepo == "Select Repo"
                ? "form-select codeEditorFormSelectDisabled"
                : "form-select codeEditorFormSelect"
            }
            aria-label="Default select example"
            disabled={selectedRepo == ""}
            onChange={handleBranchSelect}
          >
            <option selected>Select Branch</option>
            {allBranches.map((eachItem) => {
              return (
                <option value={eachItem.branchName}>
                  {eachItem.branchName}
                </option>
              );
            })}
          </select>
        </Col>

        <Col xs={2}>
          <select
            className={
              selectedBranch == "" || selectedBranch == "Select Repo"
                ? "form-select codeEditorFormSelectDisabled"
                : "form-select codeEditorFormSelect"
            }
            // class="form-select"
            aria-label="Default select example"
            disabled={selectedBranch == ""}
            onChange={() =>
              console.log("TODO: Commit Functionality is Pending to Implement")
            }
          >
            <option selected>All Commits</option>
            {allCommits.map((eachItem) => {
              return (
                <option value={eachItem.commitMessage}>
                  {eachItem.commitMessage}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>

      <Col xs={12} className="codeEditorMainCol">
        <Row className="codeEditorMainRow">
          <Col xs={3} className="codeEditorFolderTree">
            {(isButtonClicked == true || isButtonClicked == false) && (
              <Folder
                handleInsertNode={handleInsertNode}
                handleDeleteNode={handleDeleteNode}
                handleRenameNode={handleRenameNode}
                explorer={explorerData}
                tempExplorerData={tempExplorerData}
                setButtonClick={setButtonClick}
                updateTabsArray={updateTabsArray}
                isFolderTreeFetching={isFolderTreeFetching}
                handleRightClick={handleRightClick}
              />
            )}
          </Col>

          <Col xs={9} className="codeEditorEditorArea">
            <div style={{ display: "flex", flexDirection: "row" }}>
              {tabs.length
                ? tabs.map((tab) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div>
                          <button
                            key={tab.id}
                            className={
                              tab.isActivated
                                ? "codeEditorTabs1"
                                : "codeEditorTabs2"
                            }
                            onClick={() => handleTabClick(tab)}
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                          >
                            {tab.name}
                          </button>
                        </div>

                        <div>
                          <button
                            onClick={() => handleTabClose(tab.id)}
                            style={{
                              visibility: isTabHover ? "" : "hidden",
                            }}
                            className={
                              tab.isActivated
                                ? "codeEditorTabClose1"
                                : "codeEditorTabClose2"
                            }
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                          >
                            x
                          </button>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
            {editorValueToShow && (
              <Editor
                theme="vs-dark"
                height="56vh"
                onChange={handleEditorChange}
                onMount={handleEditorDidMount}
                beforeMount={handleEditorWillMount}
                onValidate={handleEditorValidation}
                defaultLanguage="javascript"
                options={""}
                defaultValue={editorValueToShow}
              />
            )}
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default CondenseEditor;
