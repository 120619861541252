import { useState, useRef, useEffect } from "react";
import { Col, Row } from "reactstrap";
import DropDownMenu from "./DropDownMenu";
import ExpandLeft from "../../assets/images/expandLeft.png";
import ExpandDropDown from "../../assets/images/expandDropDown.png";
import { selectIcon } from "./folderData";
import axiosAdapter from "../../utils";
import { env } from "../../env";

const Folder = ({
  handleInsertNode,
  handleDeleteNode,
  handleRenameNode,
  explorer,
  tempExplorerData,
  setButtonClick,
  updateTabsArray,
  isFolderTreeFetching,
  handleRightClick,
}) => {
  const menuRef = useRef(null);
  const [expand, setExpand] = useState(false);
  const [showInput, setShowInput] = useState({
    visible: false,
    isFolder: false,
  });
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [selectedId, setSelectedId] = useState(null);
  const [optionsStructure, setOptionsStructure] = useState([]);
  const [isRenameClicked, setIsRenameClicked] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuRef]);

  const handleContextMenu = (event, id, selectedType) => {
    handleRightClick(showMenu);
    if (selectedType == "folder") {
      setOptionsStructure([
        { id: 1, text: "Add Folder" },
        { id: 2, text: "Add File" },
        { id: 3, text: "Rename" },
        { id: 4, text: "Delete" },
      ]);
    } else {
      setOptionsStructure([
        { id: 3, text: "Rename" },
        { id: 4, text: "Delete" },
      ]);
    }

    event.preventDefault();
    setShowMenu(true);
    setMenuPosition({ x: event.clientX, y: event.clientY });
    setSelectedId(id);
    setButtonClick();
    const item = findItemById(id, [tempExplorerData]);
  };

  const handleNewDirectoryClicked = (type) => {
    setExpand(true);
    setShowInput({
      visible: true,
      isFolder: type == "folder" ? true : false,
    });
    setShowMenu(false);
  };

  const onAddFolder = (e) => {
    if (e.keyCode === 13 && e.target.value) {
      handleInsertNode(explorer.id, e.target.value, showInput.isFolder);

      setShowInput({ ...showInput, visible: false });
    }
  };

  const deleteFileOrFolder = async () => {
    setShowMenu(false);

    await deleteObjectById(selectedId, tempExplorerData);

    handleDeleteNode(tempExplorerData, selectedId);
    setButtonClick();
  };

  const handleRenameClicked = () => {
    getNameById(selectedId, tempExplorerData);
    setIsRenameClicked(true);
    setShowMenu(false);
  };

  const getNameById = (selectedId, tempExplorerData) => {
    if (tempExplorerData.id === selectedId) {
      setInputValue(tempExplorerData.name);
    } else if (tempExplorerData.items) {
      for (let i = 0; i < tempExplorerData.items.length; i++) {
        const name = getNameById(selectedId, tempExplorerData.items[i]);
        if (name) {
          setInputValue(name);
        }
      }
    }
  };

  const renameFolderOrFile = async (event) => {
    if (event.keyCode === 13) {
      await renameObject(selectedId, inputValue, tempExplorerData);
      handleRenameNode(tempExplorerData, selectedId, inputValue);
      setIsRenameClicked(false);
      setButtonClick();
    }
  };

  const renameObject = (id, newName, tempExplorerData) => {
    if (tempExplorerData.id === id) {
      tempExplorerData.name = newName;
    } else if (tempExplorerData.items) {
      tempExplorerData.items.forEach((item) => renameObject(id, newName, item));
    }
  };

  const deleteObjectById = (id, item) => {
    if (item.id === id) {
      return true;
    } else if (item.items) {
      let indexToRemove = -1;
      item.items.forEach((nestedItem, index) => {
        if (deleteObjectById(id, nestedItem)) {
          indexToRemove = index;
        }
      });
      if (indexToRemove !== -1) {
        item.items.splice(indexToRemove, 1);
      }
      return false;
    } else {
      return false;
    }
  };

  const findItemById = (id, items) => {
    for (let item of items) {
      if (item.id === id) {
        item.isSelected = true;
      } else {
        delete item.isSelected;
      }

      if (item.items) {
        const foundItem = findItemById(id, item.items);
      }
    }
  };

  const findItemByIdAndSetCode = (id, items) => {
    for (let item of items) {
      if (item.id === id) {
        item.isSelected = true;
      } else {
        delete item.isSelected;
      }

      if (item.items) {
        const foundItem = findItemByIdAndSetCode(id, item.items);
      }
    }
  };

  const getCodeFromUrl = async (fileUrl) => {
    try {
      const stringifiedData = {
        gitToken: "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",
        fileUrl: fileUrl,
      };

      let response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "github/getCodeOfFile",
        stringifiedData
      );

      // console.log("File Code Response: ", response.data);
      return response.data.code;
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleRowClick = (selectedItemId) => {
    setButtonClick();
    setShowMenu(false);
    setExpand(!expand);
    const item = findItemById(selectedItemId, [tempExplorerData]);
  };

  const handleFileClick = async (
    selectedFileId,
    selectedFileName,
    selectedFileUrl
  ) => {
    await setButtonClick();
    setShowMenu(false);
    const item = findItemByIdAndSetCode(selectedFileId, [tempExplorerData]);
    const selectedFileCode = await getCodeFromUrl(selectedFileUrl);
    // console.log("selectedFileCode=========", selectedFileCode);
    updateTabsArray(selectedFileId, selectedFileName, selectedFileCode);
  };

  if (explorer != {} && explorer.isFolder == true) {
    return (
      <div className="folderTreeAreaTop">
        <div
          onClick={() => handleRowClick(explorer.id)}
          onContextMenu={(event) =>
            handleContextMenu(event, explorer.id, "folder")
          }
          className={
            explorer.isSelected == true
              ? "folderTreeFolderStyling1"
              : "folderTreeFolderStyling2"
          }
        >
          <span>
            {
              <img
                src={expand ? ExpandDropDown : ExpandLeft}
                width="20.15px"
                height="19.15px"
              />
            }
            📁{" "}
            {isRenameClicked ? (
              <input
                type="text"
                autoFocus
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                onKeyDown={renameFolderOrFile}
              ></input>
            ) : (
              explorer.name
            )}
          </span>
        </div>

        <div
          className={expand ? "folderTreeInputField1" : "folderTreeInputField2"}
        >
          {showInput.visible && (
            <div className="inputContainer">
              <span>{showInput.isFolder ? "📁  " : "📄  "}</span>
              <input
                type="text"
                autoFocus
                onKeyDown={onAddFolder}
                onBlur={() => setShowInput({ ...showInput, visible: false })}
              />
            </div>
          )}

          {explorer.items.map((exp) => {
            return (
              <Folder
                handleInsertNode={handleInsertNode}
                handleDeleteNode={handleDeleteNode}
                handleRenameNode={handleRenameNode}
                key={exp.id}
                explorer={exp}
                tempExplorerData={tempExplorerData}
                setButtonClick={setButtonClick}
                updateTabsArray={updateTabsArray}
                isFolderTreeFetching={isFolderTreeFetching}
                handleRightClick={handleRightClick}
              />
            );
          })}
        </div>

        {showMenu && (
          <div
            ref={menuRef}
            style={{
              left: menuPosition.x,
              top: menuPosition.y - 68,
              position: "absolute",
              zIndex: 999,
            }}
          >
            <DropDownMenu
              optionsStructure={optionsStructure}
              handleNewDirectoryClicked={handleNewDirectoryClicked}
              deleteFileOrFolder={deleteFileOrFolder}
              handleRenameClicked={handleRenameClicked}
            />
          </div>
        )}
      </div>
    );
  } else if (explorer != {} && explorer.isFolder == false) {
    return (
      <Col xs={12}>
        <Row>
          <Col
            className={
              explorer.isSelected == true
                ? "folderTreeFileStyling1"
                : "folderTreeFileStyling2"
            }
            onClick={() =>
              handleFileClick(explorer.id, explorer.name, explorer.fileUrl)
            }
            onContextMenu={(event) =>
              handleContextMenu(event, explorer.id, "file")
            }
          >
            <Row
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  maxWidth: "calc(100% - 15px)",
                }}
              >
                <div style={{ marginRight: "5px" }}>
                  <img
                    src={selectIcon(explorer.name.split(".")[1])}
                    width="15px"
                    height="15px"
                  />
                </div>
                <div className="folderTreeRenameInputField">
                  {isRenameClicked ? (
                    <input
                      type="text"
                      autoFocus
                      value={inputValue}
                      onChange={(e) => {
                        setInputValue(e.target.value);
                      }}
                      onKeyDown={renameFolderOrFile}
                    ></input>
                  ) : (
                    explorer.name
                  )}
                </div>
              </div>
            </Row>
          </Col>
        </Row>

        {showMenu && (
          <div
            ref={menuRef}
            style={{
              left: menuPosition.x,
              top: menuPosition.y - 68,
              position: "absolute",
              zIndex: 999,
            }}
          >
            <DropDownMenu
              optionsStructure={optionsStructure}
              handleNewDirectoryClicked={handleNewDirectoryClicked}
              deleteFileOrFolder={deleteFileOrFolder}
              handleRenameClicked={handleRenameClicked}
            />
          </div>
        )}
      </Col>
    );
  } else {
    return (
      <>
        <div style={{ color: "white", marginTop: "10px" }}>
          {isFolderTreeFetching ? (
            <div style={{ textAlign: "center" }}>
              <div
                class="spinner-grow spinner-grow-sm"
                role="status"
                style={{ marginRight: "20px" }}
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <div
                class="spinner-grow spinner-grow-sm"
                role="status"
                style={{ marginRight: "20px" }}
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div>Please select Repo & Branch</div>
          )}
        </div>
      </>
    );
  }
};

export default Folder;
