import { Col, Row } from "reactstrap";
import AddNode from "../../assets/images/addNode.png";

const ConnectorsAddButton = (props) => {
  const handleAddedNewNode = props.handleAddedNewNode;

  return (
    <Row>
      <Col xs={3}>
        <img
          src={AddNode}
          height="80px"
          style={{ cursor: "pointer" }}
          onClick={handleAddedNewNode}
        />
      </Col>
    </Row>
  );
};

export default ConnectorsAddButton;
