import { useState, useContext } from "react";
import { useHistory, withRouter } from "react-router-dom";
import CommonOnboardingBackground from "./CommonOnboardingBackground";
import OnboardingWelcome from "./OnboardingWelcome";
import OnboardingWorkSpaceList from "./OnBoardingWorkspaceList";
import CommonOnboardingPrgBarTitle from "./CommonOnboardingPrgBarTitle";
import OnBoardingDeviceManufacturer from "./OnBoardingDeviceManufacturer";
// import OnBoardingDeviceManufacturer from "./OnBoardingDeviceManufacturer";
import OnBoardingDataSourcePipelineSetup from "./OnBoardingDataSourcePipelineSetup";
import OnboardingFooter from "./CommonOnboardingFooter";
import ProductLisiting from "./ProductLisiting";
import { Col, Row } from "reactstrap";
import ConfigureDevice from "./ConfigureDevice";
import axiosAdapter from "../../utils";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { env } from "../../env";
import SearchBox from "../../components/onBoardingComponents/_searchBox";

const OnboardingMainPage = () => {
  const { showMessage } = useContext(SnackbarContext);
  const history = useHistory();
  const [onBoardingState, setOnBoardingState] = useState(2);
  const [selectedWorkspaceId, setselectedWorkspaceId] = useState(null);
  const [isSourceSelected, setIsSourceSelected] = useState(false);
  const [isDeviceSelected, setIsDeviceSelected] = useState(false);
  const [isSelectedCardName, setIsSelectedCardName] = useState(null);
  const [isSelectedManufactName, setIsSelectedManufactName] = useState(null);

  const [isSelectedDevicePort, setisSelectedDevicePort] = useState(null);
  const [isSelectedNetworkType, setIsSelectedNetworkType] = useState(null);
  const [isSelectedStatus, setIsSelectedStatus] = useState(null);

  const handleOnBoardingPage = (pageState, backButtonPageName) => {
    console.log("pageState", pageState);
    if (pageState == 3) {
      setIsDeviceSelected(false);
      setOnBoardingState(pageState);
    } else if (pageState == 2 && backButtonPageName == "sourcePageBackButton") {
      setIsSourceSelected(false);
      setOnBoardingState(pageState);
    } else {
      setOnBoardingState(pageState);
    }
  };

  const handleRouteConnectorsPage = async (newWorkspace, newWorkSpaceID) => {
    if (newWorkspace && newWorkSpaceID) {
      let stringifyData = {
        id: newWorkSpaceID,
        layout: {
          node: [
            {
              id: "1",
              type: "custom",
              data: {
                label: "input",
                title: "Input Data Source",
                sourceName: "Telematics Device",
                port: Math.floor(1024 + Math.random() * (65535 - 1024 + 1)),
                deviceModel: isSelectedCardName,
                mnufactName: isSelectedManufactName,
                status: "Running",
                isConnector: false,
              },
            },
            // {
            //   id: "2",
            //   type: "customDashed",
            //   data: { label: "output", isConnector: true },
            // },
          ],
          edges: [
            {
              source: "1",
              sourceHandle: null,
              target: "2",
              targetHandle: null,
              type: "smoothstep",
              animated: true,
              id: "reactflow__edge-1-2",
            },
          ],
        },
      };

      let stringifyDataFeatureList = {
        clientId: env.REACT_APP_CLIENT_ID,
        featureKey: "TELEMATICS_DEVICE",
        workspaceId: newWorkSpaceID,
      };
      let featureDeployment = axiosAdapter(
        "POST",
        env.REACT_APP_URL + "admin/setDeploymentsForFeature",
        stringifyDataFeatureList
      );

      let setWorkspaceList = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setWorkSpace",
        stringifyData
      );
      console.log("setWorkspaceList", setWorkspaceList);
      if (setWorkspaceList && setWorkspaceList.data.status == "OK") {
        let selectedWorkspace = newWorkSpaceID;
        history.push({
          pathname: "connectorsPlayground",
          selectedWorkspace: selectedWorkspace,
        });
      } else {
        showMessage("No Workspace ID");
      }
    } else {
      history.push({
        pathname: "connectorsPlayground",
        selectedWorkspace: newWorkSpaceID,
      });
    }
  };

  const handlingWorkSpace = (workSpaceId, layout) => {
    setselectedWorkspaceId(workSpaceId);
    if (layout != null) {
      handleRouteConnectorsPage(false, workSpaceId);
    } else {
      setOnBoardingState(3);
    }
  };

  const handleSourceCardSelection = () => {
    setIsSourceSelected(!isSourceSelected);
  };

  const handleDeviceCardSelection = (selectionState) => {
    setIsDeviceSelected(selectionState);
  };

  const handleDeviceSelected = (
    selectedCardName,
    selectedManufactName,
    selectedDevicePort,
    selectedNetworkType,
    selectedStatus
  ) => {
    setIsSelectedCardName(selectedCardName);
    setIsSelectedManufactName(selectedManufactName);
    setisSelectedDevicePort(selectedDevicePort);
    setIsSelectedNetworkType(selectedNetworkType);
    setIsSelectedStatus(selectedStatus);
  };

  switch (onBoardingState) {
    case 1:
      return (
        <CommonOnboardingBackground>
          <OnboardingWelcome
            onClickConnectSource={() => handleOnBoardingPage(2)}
          />
        </CommonOnboardingBackground>
      );

    case 2:
      return (
        <CommonOnboardingBackground>
          <Col xs={12} className="onBoardingContentStyle">
            <CommonOnboardingPrgBarTitle title={"Your Workspaces"} />
            <OnboardingWorkSpaceList handlingWorkSpace={handlingWorkSpace} />
          </Col>
          <OnboardingFooter
            showNextAndBackButton={false}
            onClickBack={() => handleOnBoardingPage(1)}
            onClickNext={() => handleOnBoardingPage(3)}
          />
        </CommonOnboardingBackground>
      );

    case 3:
      return (
        <CommonOnboardingBackground>
          <Col xs={12} className="onBoardingContentStyle">
            <CommonOnboardingPrgBarTitle title={"Your Workspaces"} />
            <OnBoardingDataSourcePipelineSetup
              isSourceSelected={isSourceSelected}
              handleSourceCardSelection={handleSourceCardSelection}
            />
          </Col>
          <Col xs={12}>
            <OnboardingFooter
              isSourceSelected={isSourceSelected}
              showNextAndBackButton={true}
              onClickBack={() =>
                handleOnBoardingPage(2, "sourcePageBackButton")
              }
              onClickNext={() => handleOnBoardingPage(5)}
            />
          </Col>
        </CommonOnboardingBackground>
      );
    case 4:
      return (
        <CommonOnboardingBackground>
          <Row>
            <Col xs={12} className="onBoardingContentStyle">
              <CommonOnboardingPrgBarTitle
                title={"Setup your first Pipeline"}
              />
              <OnBoardingDataSourcePipelineSetup />
            </Col>
          </Row>
          <OnboardingFooter />
        </CommonOnboardingBackground>
      );
    // case 5:
    //   return (
    //     <CommonOnboardingBackground>
    //       <CommonOnboardingPrgBarTitle title={"Setup your first Pipeline"} />
    //       <OnBoardingDeviceManufacturer />
    //     </CommonOnboardingBackground>
    //   );
    case 5:
      return (
        <CommonOnboardingBackground>
          <Col xs={12} className="onBoardingContentStyle">
            <CommonOnboardingPrgBarTitle title={"Setup your first Pipeline"} />
            <ProductLisiting
              handleDeviceCardSelection={handleDeviceCardSelection}
              handleDeviceSelected={handleDeviceSelected}
            />
          </Col>
          <OnboardingFooter
            isDeviceSelected={isDeviceSelected}
            showNextAndBackButton={true}
            onClickBack={() => handleOnBoardingPage(3)}
            onClickNext={() =>
              handleRouteConnectorsPage(true, selectedWorkspaceId)
            }
            buttonTxt="DONE"
          />
        </CommonOnboardingBackground>
      );

    case 6:
      return (
        <CommonOnboardingBackground>
          <Col xs={12} className="onBoardingContentStyle">
            <CommonOnboardingPrgBarTitle
              title={"Setup your First Data Source"}
            />
            <ConfigureDevice />
          </Col>
          <OnboardingFooter
            showNextAndBackButton={true}
            onClickBack={() => handleOnBoardingPage(5)}
            onClickNext={() =>
              handleRouteConnectorsPage(true, selectedWorkspaceId)
            }
            buttonTxt="DONE"
          />
        </CommonOnboardingBackground>
      );

    // case 7:
    //   return (
    //     <CommonOnboardingBackground>
    //       <StyledTopSnackBar text={"Please login, There is an error"}/>
    //       {/* <OnBoardingAddedConnectors /> */}
    //     </CommonOnboardingBackground>
    //   );

    default:
      return (
        <CommonOnboardingBackground>
          <OnboardingWelcome
            onClickConnectSource={() => handleOnBoardingPage(1)}
          />
        </CommonOnboardingBackground>
      );
  }
};

export default withRouter(OnboardingMainPage);
